import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth, logout } from '../auth';

const LoggedInLinks = () => {
  return (
    <>
      <li className="nav-item">
        <Link className="nav-link active" to="/">
          Αρχική
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link  active" to="/manage_queue">
          Διαχέιριση Ουράς
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link  active" to="/monitor">
          Παρακολούθηση
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link  active" to="/printer">
          Εκτυπωτής
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link  active" to="/Analytics">
          Στατιστικά
        </Link>
      </li>

      <li className="nav-item">
        <a
          className="nav-link active"
          href="#"
          onClick={() => {
            logout();
          }}
        >
          Αποσύνδεση
        </a>
      </li>
    </>
  );
};

const LoggedOutLinks = () => {
  return (
    <>
      <li className="nav-item">
        <Link className="nav-link active" to="/">
          Home
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" to="/signup">
          Εγγραφή
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" to="/login">
          Σύνδεση
        </Link>
      </li>
    </>
  );
};

const NavBar = () => {
  const [logged] = useAuth();
  const location = useLocation();

  const shouldRenderNavBar =
    location.pathname !== '/printer' && location.pathname !== '/monitor';

  if (!shouldRenderNavBar) {
    return null;
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          ΚΕΠ
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {logged ? <LoggedInLinks /> : <LoggedOutLinks />}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
