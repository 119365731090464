import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
//import { getQueueNumbers } from './APIcomponents/getQueueNumbers';
import { printTicket } from '../components/APIcomponents/printTicket';
import { nextInQueue } from '../components/APIcomponents/nextInQueue';

function ManageQueuePage() {

  const getQueueNumbers = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    };

    fetch('/line/', requestOptions)
      .then((res) => {
        if (!res) {
          throw new Error('HTTP error! Status: ${res.status');
        }
        return res.json(); // expecting a JSON response.
      })
      .then((data) => {
        //console.log('Data fetched:', data);
        const newArray = data.map((item) => `${item.num_text}${item.num}`);
        setCombinedArray(newArray);
        console.log(newArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    const requestOptions2 = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    };

    fetch('/first/', requestOptions2)
      .then((res) => {
        if (!res) {
          throw new Error('HTTP error! Status: ${res.status');
        }
        return res.json(); // expecting a JSON response.
      })
      .then((data1) => {
        //console.log('Data fetched:', data);
        const currentArray = data1.map((item) => `${item.num_text}${item.num}`);
        setServiced(currentArray);
        console.log(currentArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const fetchQueueNumbers = async () => {
    try {
      const [lineData, firstData] = await getQueueNumbers();
      const newArray = lineData.map((item) => `${item.num_text}${item.num}`);
      const currentArray = firstData.map(
        (item) => `${item.num_text}${item.num}`
      );

      setCombinedArray(newArray);
      setServiced(currentArray);
    } catch (error) {
      console.error('Error fetching queue numbers:', error);
    }
  };

  const handlePrintTicket = async () => {
    await printTicket();
    try {
      const [lineData, firstData] = await getQueueNumbers();
      const newArray = lineData.map((item) => `${item.num_text}${item.num}`);
      const toBePrinted = lineData.slice(-1)[0].num + 1;
      setCombinedArray(newArray);
      setToBePrint(toBePrinted);

      const currentArray = firstData.map(
        (item) => `${item.num_text}${item.num}`
      );
      setServiced(currentArray);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const next = async () => {
    try {
      await nextInQueue();
      await fetchQueueNumbers();
    } catch (error) {
      console.error('Error in next:', error);
    }
  };

  useEffect(() => {
    // Fetch queue numbers when the component mounts
    const fetchQueueNumbers = async () => {
      try {
        const [lineData, firstData] = await getQueueNumbers();
        const newArray = lineData.map((item) => `${item.num_text}${item.num}`);
        const currentArray = firstData.map(
          (item) => `${item.num_text}${item.num}`
        );

        setCombinedArray(newArray);
        setServiced(currentArray);
      } catch (error) {
        console.error('Error fetching queue numbers:', error);
      }
    };

    fetchQueueNumbers();
  }, []);

  useEffect(() => {
    fetchQueueNumbers();

    const intervalId = setInterval(() => {
      fetchQueueNumbers();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);


  const [CombinedArray, setCombinedArray] = useState([]);
  const [serviced, setServiced] = useState([]);
  // Have the element that will be printed.
  const [toBePrint, setToBePrint] = useState([]);

  return (
    <div className="mx-auto">
      <div className="container">
        <div className="mt-4 flex flex-col border-2 font-thin border-black rounded-lg shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-10 py-2 ">
          <h1>Εξυπηρετείται:</h1>
          <br></br>
          <span className="current-cit">{serviced}</span>
          <br />
          <span>Μέγεθος σειράς: {CombinedArray.length}</span>
          <br />
        </div>
        <div className="justify-center">
          <h3 className="text-center mb-5">Αναπαράσταση σειράς.</h3>
          <div className="queueDisplay">
            {CombinedArray.slice(0, 5).map((item, index) => (
              <span key={index} className="queueItem">
                {item}
              </span>
            ))}
          </div>
        </div>
        <h1 className="text-center">Διαχέιριση Ουράς</h1>
        <div className="mt-4 border-2 ">
          <Button
            className="border-black z-10 px-4 py-2 custom-button"
            style={{ fontSize: '2.5em' }}
            onClick={next}
          >
            ΕΠΟΜΕΝΟΣ{' '}
          </Button>
          <br></br>
          <Button
            className="border-black z-10 px-4 py-2 padding-bottom 4px custom-button"
            style={{ fontSize: '2.5em' }}
            onClick={handlePrintTicket}
          >
            ΕΚΔΟΣΗ ΕΙΣΙΤΗΡΙΟΥ
            {` A${toBePrint || ''}`}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ManageQueuePage;
