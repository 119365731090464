import { Button } from 'react-bootstrap';
import React from 'react';

const PrinterUi = () => {
  const printTicket = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
    };

    fetch('/line/', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // If you expect a JSON response
      })
      .then((data) => {
        console.log('Request succeeded with JSON response:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1></h1>
      <div className="printerlayout">
        <Button onClick={printTicket}>
          ΕΚΤΥΠΩΣΗ <br></br>- PRINT -
        </Button>
      </div>
    </div>
  );
};

export default PrinterUi;
