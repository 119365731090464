import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const MonitorPage = () => {
  // data is the array of object we GET from the API
  const [CombinedArray, setCombinedArray] = useState([]);
  const [serviced, setServiced] = useState([]);

  const getQueueNumbers = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    };

    fetch('/line/', requestOptions)
      .then((res) => {
        if (!res) {
          throw new Error('HTTP error! Status: ${res.status');
        }
        return res.json(); // expecting a JSON response.
      })
      .then((data) => {
        //console.log('Data fetched:', data);
        const newArray = data.map((item) => `${item.num_text}${item.num}`);
        setCombinedArray(newArray);
        console.log(newArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    const requestOptions2 = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    };

    fetch('/first/', requestOptions2)
      .then((res) => {
        if (!res) {
          throw new Error('HTTP error! Status: ${res.status');
        }
        return res.json(); // expecting a JSON response.
      })
      .then((data1) => {
        //console.log('Data fetched:', data);
        const currentArray = data1.map((item) => `${item.num_text}${item.num}`);
        setServiced(currentArray);
        console.log(currentArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getQueueNumbers();

    const intervalId = setInterval(() => {
      getQueueNumbers();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="splitScreen">
      <div className="left-side-container border-2 rounded-lg shadow-5px p-4 float-left">
        <span key={serviced} className="current-citmonitor">{serviced}</span>
      </div>

      <div className="right">
        <h1 className="heading">ΕΠΟΜΕΝΑ:</h1>
        <div className="queueDisplayMonitor">
          {CombinedArray.slice(0, 5).map((item, index) => (
            <span key={index} className="queueItemMonitor">
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MonitorPage;
