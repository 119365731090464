const nextInQueue = () => {
  const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
  console.log(token);
  const usernameLocal = localStorage.getItem('username');
  console.log(usernameLocal);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${JSON.parse(token)}`,
    },
    body: JSON.stringify({ email: localStorage.getItem(usernameLocal) }),
  };
  return fetch('/first/', requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        console.log(token);
      }
      return response.json(); // If you expect a JSON response
    })
    .then((data) => {
      console.log('Request succeeded with JSON response:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export { nextInQueue };
