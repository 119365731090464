import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
import 'font-awesome/css/font-awesome.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-datepicker';

const DualDatePickerComponent = ({
  label1 = 'Date1',
  label2 = 'Date2',
  onStartDateChange,
  onEndDateChange,
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Initialize datepickers
    const datepicker1 = $('#datepicker1').datepicker();
    const datepicker2 = $('#datepicker2').datepicker();

    // Update parent component when dates change
    datepicker1.on('changeDate', function (e) {
      const selectedDate = e.date;
      setStartDate(selectedDate);
      onStartDateChange(selectedDate);
    });

    datepicker2.on('changeDate', function (e) {
      const selectedDate = e.date;
      setEndDate(selectedDate);
      onEndDateChange(selectedDate);
    });
  }, [onStartDateChange, onEndDateChange]); // Dependency array ensures the effect runs when callbacks change

  return (
    <div style={{ backgroundColor: 'white' }}>
      <section className="container">
        <h3 className="pt-4 pb-2">Επιλέξτε έυρος εμφάνισης στατιστικών</h3>
        <form>
          {/* First Datepicker */}
          <div className="row form-group">
            <label
              htmlFor="date1"
              className="col-sm-1 col-form-label"
              style={{ fontWeight: 'bold', marginRight: '20px' }}
            >
              {label1}
            </label>
            <div className="col-sm-4">
              <div className="input-group date" id="datepicker1">
                <input type="text" className="form-control" />
                <span className="input-group-append">
                  <span className="input-group-text bg-white">
                    <i className="fa fa-calendar"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/* Second Datepicker */}
          <div className="row form-group">
            <label
              htmlFor="date2"
              className="col-sm-1 col-form-label"
              style={{ fontWeight: 'bold', marginRight: '20px' }}
            >
              {label2}
            </label>
            <div className="col-sm-4">
              <div className="input-group date" id="datepicker2">
                <input type="text" className="form-control" />
                <span className="input-group-append">
                  <span className="input-group-text bg-white">
                    <i className="fa fa-calendar"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default DualDatePickerComponent;
