import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const SignupPage = () => {
  const [show, setShow] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitForm = (data) => {
    if (data.password === data.confirmPassword) {
      const body = {
        username: data.username,
        email: data.email,
        password: data.password,
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // other headers if needed
        },
        body: JSON.stringify(body),
      };

      fetch('/auth/signup', requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setServerResponse(data.message);
          console.log(serverResponse);
          setShow(true);
        })
        .catch((error) => console.log(error));

      reset();
    } else {
      alert('Passwords fo not match');
    }
  };

  return (
    <div className="container">
      <div className="form">
        {show ? (
          <>
            <Alert variant="success" onClose={() => setShow(false)} dismissible>
              <p>{serverResponse}</p>
            </Alert>
            <h1>Sign Up Page</h1>
          </>
        ) : (
          <h1>Εγγραφή</h1>
        )}
        <br></br>
        <Form>
          <Form.Group>
            <Form.Label>Όνομα χρήστη</Form.Label>
            <Form.Control
              type="text"
              placeholder="Το όνομα χρήστη σας"
              {...register('username', { required: true, maxLength: 25 })}
            />
            {errors.username && (
              <p style={{ color: 'red' }}>
                <small>Το όνομα χρήστη είναι απαραίτητο.</small>
              </p>
            )}
            {errors.username?.type === 'maxLength' && (
              <p style={{ color: 'red' }}>
                <small>Μέγιστοι χαρακτήρες 25.</small>
              </p>
            )}
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Το email σας "
              {...register('email', { required: true, maxLength: 80 })}
            />
            {errors.email && (
              <p style={{ color: 'red' }}>
                <small>To Email είναι απαραίτητο.</small>
              </p>
            )}
            {errors.email?.type === 'maxLength' && (
              <p style={{ color: 'red' }}>
                <small>Μέγιστοι χαρακτήρες 80.</small>
              </p>
            )}
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>Κωδικός</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ο κωδικός σας"
              {...register('password', { required: true, minLength: 8 })}
            />
            {errors.password && (
              <p style={{ color: 'red' }}>
                <small>Ο κωδικός είναι απαραίτητος.</small>
              </p>
            )}
            {errors.password?.type === 'minLength' && (
              <p style={{ color: 'red' }}>
                Min characters should be equal to 8
              </p>
            )}
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Label>Επιβεβαίωση Κωδικού</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ο κωδικός σας πάλι"
              {...register('confirmPassword', { required: true, minLength: 8 })}
            />
            {errors.confirmPassword && (
              <p style={{ color: 'red' }}>
                <small>Ο κωδικός σας πάλι είναι απαραίτητος.</small>
              </p>
            )}
            {errors.confirmPassword?.type === 'minLength' && (
              <p style={{ color: 'red' }}>Οι ελάχιστοι χαρακτήρες είναι 8</p>
            )}
          </Form.Group>
          <Form.Group>
            <br></br>
            <Button
              as="sub"
              variant="primary"
              onClick={handleSubmit(submitForm)}
            >
              Εγραφή
            </Button>
          </Form.Group>
          <Form.Group>
            <br></br>
            <small>
              Έχετε ήδη λογαριαμσό; <Link to="/login">Σύνδεση</Link>
            </small>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default SignupPage;
