import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth';
import KepLogo from '../assets/KEP_(GR)_logo.png';

const LoggedinHome = () => {
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    fetch('/recipe/recipes')
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setQueue(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const linkContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  };

  const linkStyle = {
    fontSize: '0.8em',
    margin: '5px',
  };

  return (
    <div className="Home" style={containerStyle}>
      <div className="HomeLogo" style={logoStyle}>
        <img src={KepLogo} alt="Logo" />
      </div>
      <h1 style={{ color: '#9a9d9d', fontSize: '16px', paddingLeft: '5px' }}>
        ΔΗΜΟΥ ΙΘΑΚΗΣ{' '}
      </h1>
    </div>
  );
};

const LoggedoutHome = () => {
  return (
    <div className="Home">
      <div className="HomeLogo">
        <img src={KepLogo}></img>
      </div>
      <Link to="/signup" className="btn btn-primary btn-lg">
        Εγγραφή
      </Link>
      <Link to="/login" className="btn btn-primary btn-lg">
        Σύνδεση
      </Link>
    </div>
  );
};

const HomePage = () => {
  const [logged] = useAuth();
  return <div>{logged ? <LoggedinHome /> : <LoggedoutHome />}</div>;
};

export default HomePage;

/*

<div style={linkContainerStyle}>
        <Link
          to="/manage_queue"
          className="btn btn-primary btn-lg"
          style={linkStyle}
        >
          Διαχείριση
        </Link>
        <Link
          to="/monitor"
          className="btn btn-primary btn-lg"
          style={linkStyle}
        >
          Παρακολούθηση
        </Link>
      </div>
      <div style={linkContainerStyle}>
 Group the next two links together 
        <Link
          to="/printer"
          className="btn btn-primary btn-lg"
          style={linkStyle}
        >
          Εκτυπωτής
        </Link>
        <Link
          to="/Analytics"
          className="btn btn-primary btn-lg"
          style={linkStyle}
        >
          Στατιστικά
        </Link>
      </div>

*/
