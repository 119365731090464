import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login } from '../auth';
import { useNavigate } from 'react-router-dom';

const LoginInPage = () => {
  const {
    register,
    handleSubmit,

    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const loginUser = (data) => {
    console.log(data);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    const serializedusername = JSON.stringify(data.username);
    localStorage.setItem('username', serializedusername);

    fetch('/auth/login', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.access_token);
        login(data.access_token);

        navigate('/');
      });

    reset();
  };

  return (
    <div className="container">
      <div className="form">
        <h1>Σύνδεση</h1>
        <Form>
          <Form.Group>
            <Form.Label>Όνομα χρήστη</Form.Label>
            <Form.Control
              type="text"
              placeholder="Το όνομα χρήστη σας."
              {...register('username', { required: true, maxLength: 25 })}
            />
          </Form.Group>
          {errors.username && (
            <p style={{ color: 'red' }}>
              <small>Το Ονόμα χρήστη είναι απαραίτητο.</small>
            </p>
          )}
          {errors.username?.type === 'maxLength' && (
            <p style={{ color: 'red' }}>
              <small>Υπέρβαση μέγιστων χαρακτήρων.</small>
            </p>
          )}
          <br></br>
          <Form.Group>
            <Form.Label>Κωδικός</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ο κωδικός σας"
              {...register('password', { required: true, minLength: 8 })}
            />
          </Form.Group>
          {errors.password && (
            <p style={{ color: 'red' }}>
              <small>Ο κωδικός είναι απαράιτητος.</small>
            </p>
          )}
          {errors.password?.type === 'minLength' && (
            <p style={{ color: 'red' }}>
              <small>Οι ελάχιστοι χαρακτήρες είναι 8.</small>
            </p>
          )}

          <br></br>
          <Form.Group>
            <Button
              as="sub"
              variant="primary"
              onClick={handleSubmit(loginUser)}
            >
              Σύνδεση
            </Button>
          </Form.Group>
          <Form.Group>
            <br></br>
            <small>
              Δεν έχετε λογαριασμό; <Link to="/signup">Δημιουργία</Link>
            </small>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default LoginInPage;
