import React, { useState, useEffect } from 'react';
import { getAnalytics } from './APIcomponents/getAnalytics';
import { getAnalyticsReview } from './APIcomponents/getAnalyticsReview';
import DatePickerComponent from './APIcomponents/UtilitiesComponents/DatepickerComponent';
import { Button } from 'react-bootstrap';

const AnalyticsPage = () => {
  const [jsonAnalytics, setJsonAnalytics] = useState(null);
  const [jsonAnalyticsReview, setJsonAnalyticsReview] = useState(null);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  // Variables for the analytics
  //const [numCustomers, setNumCustomers] = useState(null);
  //const [waitTime, setWaitTime] = useState(null);
  //const [serviceTime, setServiceTime] = useState(null);

  const fetchData = async () => {
    // Clear previous data.
    setJsonAnalytics(null);
    setJsonAnalyticsReview(null);
   
  
    try {
      const [analyticsData, analyticsReviewData] = await Promise.all([
        getAnalytics(start_date, end_date),
        getAnalyticsReview(start_date, end_date)
      ]);
  
      setJsonAnalytics(analyticsData || []);
      console.log(analyticsData);
  
      setJsonAnalyticsReview(analyticsReviewData || []);
      console.log(analyticsReviewData);
  
    } catch (error) {
      console.error('Error fetching analytics data:', error.message);
    }


  };
  const calculateAnalytics = () => {
    /* || !jsonAnalyticsReview.length0*/
    if (!jsonAnalytics.length ) {
      return null;
    }




    // Calculate number of customers.
    const numberOfCustomers = jsonAnalytics.length;

    // Average wait time and average service time.
    let totalWaitTime = 0;
    let totalServiceTime = 0;

    jsonAnalytics.forEach((customer) => {
      const createAt = new Date(customer.create_at);
      const firstCreateAt = new Date(customer.first_create_at);
      const pastCreateAt = new Date(customer.past_create_at);

      const waitTime = (firstCreateAt - createAt) / (1000 * 60); // Convert milliseconds to minutes
      const serviceTime = (pastCreateAt - firstCreateAt) / (1000 * 60); // Convert milliseconds to minutes

      totalWaitTime += waitTime;
      totalServiceTime += serviceTime;
    });

    const averageWaitTime = totalWaitTime / numberOfCustomers;
    const averageServiceTime = totalServiceTime / numberOfCustomers;
    const daysDifference = Math.ceil(
      (new Date(end_date) - new Date(start_date)) / (1000 * 60 * 60 * 24)
    );
    const customersPerDay = numberOfCustomers / daysDifference;


    let sumOfSad = 0;
    let sumOfOk = 0;
    let sumOfHappy = 0;

    // Vars for the reviews.
  jsonAnalyticsReview.forEach((review) => {
    if (review.stars === 5 || review.stars === 4) {
      sumOfHappy++;
    } else if (review.stars === 3) {
      sumOfOk++;
    } else if (review.stars === 1 || review.stars === 2) {
      sumOfSad++;
    }
  });

    const averageRating = (sumOfHappy + sumOfOk + sumOfSad )/jsonAnalyticsReview.length;

    return [
      numberOfCustomers.toFixed(0),
      averageWaitTime.toFixed(2),
      averageServiceTime.toFixed(2),
      customersPerDay.toFixed(0),
      sumOfHappy,
      sumOfOk,
      sumOfSad,
      averageRating
    ];
  };

  return (
    <div>
      <h1 style={{ margin: 20 }}>Στατιστικά</h1>
      <div className="datePickerStyle">
        <DatePickerComponent
          label1="Από"
          label2="Έως"
          onStartDateChange={(date) => setStartDate(date)}
          onEndDateChange={(date) => setEndDate(date)}
        />
      </div>
      <Button
        style={{ margin: 20, marginLeft: 300, fontSize: '1.5em' }}
        onClick={fetchData}
      >
        Αναζήτηση
      </Button>
      {jsonAnalytics ? (
        <div>
           <h2 style={{ margin: 20 }}>Στοιχεία:</h2>
          {(() => {
            const analyticsData = calculateAnalytics(); // Calculate once
            return analyticsData ? (
              <div className="statistics-container" style={{display:'flex', justifyContent:'space-around', gap:'10px'}}>
              <div className="statistics">
                <div>Αριθμός επισκεπτών: {analyticsData[0]}</div>
                <div>Μέσος χρόνος αναμονής: {analyticsData[1]} Λεπτά</div>
                <div>Μέσος χρόνος εξυπηρέτησης: {analyticsData[2]} Λεπτά</div>
                <div>Επισκέπτες ανά ημέρα: {analyticsData[3]}</div>
              </div>
              <div className="statistics">
                <div>Αριθμός αξιολόγησεων ευχαριστημένων πελατών: {analyticsData[4]}</div>
                <div>Αριθμός αξιολόγησεων ικανοποιημένων πελατών: {analyticsData[5]}</div>
                <div>Αριθμός αξιολόγησεων δυσαρεστημένων πελατών: {analyticsData[6]}</div>
                <div>Μέση αξιολόγηση πελατών: {analyticsData[7]}</div>
              </div>
            </div>
            ) : <div>Έλλειψη Στοιχείων </div>;
          })()}
        </div>
      ) : (
        <h2 style={{ margin: 20 }}>Έλλειψη Στοιχείων</h2>
      )}
    </div>
  );
};

export default AnalyticsPage;
