// printerFunctions.js
const printTicket = () => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
  };

  return fetch('/line/', requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // If you expect a JSON response
    })
    .then((data) => {
      console.log('Request succeeded with JSON response:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export { printTicket };
