import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
//import { Navigate } from 'react-router-dom';

const CreateRecipePage = () => {
  const { register, handleSubmit, reset } = useForm();

  const createRecipe = (data) => {
    console.log(data);

    const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    console.log(token);

    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
      body: JSON.stringify(data),
    };

    fetch('/recipe/recipes', requestOptions)
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    reset();
  };

  return (
    <div className="container">
      <h1>Create A Recipe </h1>
      <Form>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" {...register('title')} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            row={5}
            {...register('description')}
          ></Form.Control>
        </Form.Group>
        <br></br>
        <Form.Group>
          <Button variant="primary" onClick={handleSubmit(createRecipe)}>
            Save
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default CreateRecipePage;
