import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import NavBar from './components/Navbar';
import './styles/main.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/Home';
import SignupPage from './components/SignUp';
import CreateRecipePage from './components/CreateRecipe';
import LoginInPage from './components/Login';
import ManageQueue from './components/ManageQueue';
import MonitorPage from './components/MonitorPage';
import PrinterUi from './components/PrinterUi';
import AnalyticsPage from './components/AnalyticsPage';
import ReviewPage from './components/ReviewPage';


const App = () => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <div onContextMenu={handleContextMenu}>
      {/* Your content goes here */}

      <Router>
        <div className="">
          <NavBar />
          <Routes>
            <Route path="/printer/*" element={<PrinterUi />} />
            <Route path="/monitor/*" element={<MonitorPage />} />
            <Route path="/create_recipe" element={<CreateRecipePage />} />
            <Route path="/login" element={<LoginInPage />} />
            <Route path="/manage_queue" element={<ManageQueue />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/Analytics" element={<AnalyticsPage />} />
            <Route path="/reviewIt" element={<ReviewPage />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);
