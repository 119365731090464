import React from 'react';
import { Button } from 'react-bootstrap';
import KepLogo from '../assets/KEP_(GR)_logo.png';
import AkwFace from '../assets/ReviewFaces/akward.png';
import AngFace from '../assets/ReviewFaces/AngryFACE.png';
import WorFace from '../assets/ReviewFaces/WorriedFace.png';
import HappFace from '../assets/ReviewFaces/HappyFace.png';
import VeryHappyFace from '../assets/ReviewFaces/VeryHappy.png';

const ReviewIt = () => {
  const saveReview = (event) => {
    const clickedFaceId = event.target.id || event.target.parentElement.id; // Extract the button id from the event
    // Create a JSON object with the clicked face id
    const reviewJSON = JSON.stringify({ stars: Number(clickedFaceId) });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: reviewJSON,
    };

    fetch('/review/', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // If you expect a JSON response
      })
      .then((data) => {
        console.log('Request succeeded with JSON response:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '10vh',
  };

  return (
    <div>
      <h1></h1>
      <div
        style={{
          marginTop: '250px',
          margin: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div
          className="reviewPage"
          style={{ display: 'flex', justifyContent: 'center', background:'#0d6efd' }}
        >
          <Button
            onClick={(event) => saveReview(event)}
            id="1"
            className="button"
          >
            <img src={AngFace} width={150} height={150} alt="" />
          </Button>
          <Button onClick={(event) => saveReview(event)} id="2">
            {' '}
            <img src={WorFace} width={150} height={150} />
          </Button>
          <Button onClick={(event) => saveReview(event)} id="3">
            {' '}
            <img src={AkwFace} width={150} height={150} />
          </Button>
          <Button onClick={(event) => saveReview(event)} id="4">
            {' '}
            <img src={HappFace} width={150} height={150} />
          </Button>
          <Button onClick={(event) => saveReview(event)} id="5">
            <img src={VeryHappyFace} width={150} height={150} />
          </Button>
        </div>
      </div>
      <div className="HomeLogo" style={logoStyle}>
        <img
          src={KepLogo}
          alt="Logo"
          style={{ width: '400px', height: '152px' }}
        />
      </div>
    </div>
  );
};

export default ReviewIt;
