const getAnalytics = async (start_date, end_date) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    // Include the date parameters in the request body
    body: JSON.stringify({
      data: {
        start_date,
        end_date,
      },
    }),
  };

  try {
    const response = await fetch('/statistics/', requestOptions);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorMessage}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching analytics data:', error.message);
    throw error; // Re-throw the error to be caught by the caller if needed
  }
};

export { getAnalytics };
